import { Col, Container, Row } from "react-bootstrap";

export default function Footer() {
  return (
    <div className="bg-dark">
      <Container>
        <Row>
          <Col className="text-center py-5">
            <img src="/logo-sm.png" alt="Amazon Garden Services" />
            <p>&copy; 2024 Amazon Garden Services Ltd. All rights reserved. Website by SSC.</p>
          </Col>
        </Row>
      </Container>
    </div>
  )
}