import { Col, Container, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMap, faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import ContactForm from "../components/ContactForm";

export default function Contact() {
  return (
    <div>
      <Helmet>
        <title>Amazon Garden Services - Contact</title>
      </Helmet>
      <div style={{background: "no-repeat center url(/contact/bg.jpg)", backgroundSize: "cover", position: "relative", paddingTop: "120px", paddingBottom: "120px"}}>
          <h1 className="text-center" style={{color: "#FFF"}}>Contact</h1>
      </div>
      <Container style={{paddingTop: "100px", paddingBottom: "100px"}}>
        <Row>
          <Col>
            <h2 className="section-title text-center" style={{marginBottom: "40px"}}>Contact Information</h2>
            <p className="text-center">Please use the details below to contact us:</p>
            <ul className="list-unstyled text-center">
              <li className="d-block">
                <FontAwesomeIcon icon={faMap} style={{marginRight: '8px'}} /> <p className="d-inline-block mb-0">Amazon Garden Services Ltd, 16 Corfe Close, Hill Head, Fareham, Hampshire PO14 3NN</p>
              </li>
              <li className="d-inline-block" style={{marginRight: '24px'}}>
                <FontAwesomeIcon icon={faEnvelope} style={{marginRight: '8px'}} /> <p className="d-inline-block mb-0"><a href="mailto:info@amazongardenservices.co.uk">info@amazongardenservices.co.uk</a></p>
              </li>
              <li className="d-inline-block">
                <FontAwesomeIcon icon={faPhone} style={{marginRight: '8px'}} /> <p className="d-inline-block mb-0"><a href="tel:07808 763230">07808 763230</a></p>
              </li>
            </ul>
          </Col>
        </Row>
        <Row>
          <Col md={6} className="mb-4">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2520.604873587182!2d-1.2301309842412764!3d50.81995907952805!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487465eac8a719f7%3A0x53355cd9dd688596!2s16%20Corfe%20Cl%2C%20Fareham%20PO14%203NN!5e0!3m2!1sen!2suk!4v1576068720333!5m2!1sen!2suk" width="100%" height="400" title="Google Maps" frameBorder="0" style={{border:0}} allowFullScreen={false}></iframe>
          </Col>
          <Col md={6} style={{position: "relative"}}>
            <ContactForm />
          </Col>
        </Row>
      </Container>
    </div>
  );
}