import { Col, Container, Figure, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";

export default function Services() {
  return (
    <div>
      <Helmet>
        <title>Amazon Garden Services - Services</title>
      </Helmet>
      <div style={{background: "no-repeat center url(/services/bg.jpg)", backgroundSize: "cover", position: "relative", paddingTop: "120px", paddingBottom: "120px"}}>
          <h1 className="text-center" style={{color: "#FFF"}}>Services</h1>
      </div>
      <Container style={{paddingTop: "100px", paddingBottom: "100px"}}>
        <h2 className="section-title text-center" style={{marginBottom: "40px"}}>What we can do for you</h2>

        <Row>
          <Col>
            <p className="text-center">We work with small and large businesses alike. With previous clients ranging from business parks, estate maintenance, residential homes and industrial estates, we are experienced in both small and large scale grounds to a high standard.</p>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col>
            <div className="w-100">
              <video preload="metadata" poster="services/fs1.png" playsInline autoPlay muted loop className="w-100">
                <source src="/services/fs.mp4" type="video/mp4" />
              </video>
            </div>
          </Col>
        </Row>

        <Row className="mb-4">
          <Col md={6}>
            <h4>Contract Services</h4>
            <p>A full range of garden maintenance services:</p>
            <ul>
              <li>Hedge Cutting</li>
              <li>Mowing - Hand to Ride On</li>
              <li>Fertilisation and Weed Control</li>
              <li>Small Tree Cutbacks</li>
              <li>Rough Area Clearances</li>
            </ul>
            <Figure>
              <Figure.Image 
                src="/services/s1.jpg"
                alt="Contract Services"
              />
            </Figure>

            <p>Amazon Garden Services also offers a complete maintenance, cleaning service for those businesses that have a minimal requirement for green care maintenance. We are happy to discuss your contract requirements with no obligation. Call us on 07808 763230</p>
          </Col>
          <Col md={6}>
            <h4>One Off to Long Term Contracts</h4>
            <p>Let us take care of:</p>
            <ul>
              <li>Lawn care and maintenance</li>
              <li>Shrub reshaping and pruning</li>
              <li>Feeding and fertilising</li>
              <li>Hedge cutting and trimming</li>
              <li>Weed removal, Border care & Tree care</li>
            </ul>
            <Figure>
              <Figure.Image 
                src="/services/s2.jpg"
                alt="One Off to Long Term Contracts"
              />
            </Figure>

            <p>We are able to offer one off visits and long term care services to ensure your garden remains looking at its best throughout the year. From lawn maintenance to pruning, planting and hedge cutting.</p>
          </Col>
        </Row>
        
        <Row className="mb-4">
          <p className="text-center">All our work is carried out to the same high standards using only the best in garden care products and equipment. And once the work has been completed, we'll gladly provide you with expert, friendly advice to manage your garden well.</p>
        </Row>

        <Row>
          <Col md={6} className="mb-4">
            <Row>
              <Col>
                <Figure>
                  <Figure.Image 
                    src="/services/lm.jpg"
                    alt="Lawn Maintenance"
                  />
                </Figure>
                <h4>Lawn Maintenance</h4>
                <p>All types of lawn maintenance including: Lawn treatment - Aeration - Feeding - Weeding - Cutting Formal and Amenity areas - Strimming of grassed banks - Grass Seeding - Turfing</p>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <Figure>
                  <Figure.Image 
                    src="/services/he.jpg"
                    alt="Hedges"
                  />
                </Figure>
              </Col>
              <Col md={8}>
                <h4>Hedges</h4>
                <p>We can provide full management services for your hedges including Hedge Cutting and hedge reduction.</p>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <Figure>
                  <Figure.Image 
                    src="/services/bs.jpg"
                    alt="Beds &amp; Shrubberies"
                  />
                </Figure>
              </Col>
              <Col md={8}>
                <h4>Beds &amp; Shrubberies</h4>
                <p>We can mange all aspects of your beds and shrubberies requirements including: Planting - Pruning - Fertilisers - Edging - Weed Control</p>
              </Col>
            </Row>
          </Col>

          <Col md={6} className="mb-4"> 
            <Row>
              <Col>
                <Figure>
                  <Figure.Image 
                    src="/services/om.jpg"
                    alt="Other Maintenance"
                  />
                </Figure>
                <h4>Other Maintenance</h4>
                <p>
                  Leaf Clearance - Litter Collection <br />
                  Mulch - Moss Treatment <br />
                  Sweeping - Weed Control
                </p>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <Figure>
                  <Figure.Image 
                    src="/services/cl4.jpg"
                    alt="Internal Cleaning"
                  />
                </Figure>
              </Col>
              <Col md={8}>
                <h4>Internal Cleaning</h4>
                <p>We also offer an internal cleaning service of communal stairways, which includes cleaning, vacuuming and window cleaning.</p>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <Figure>
                  <Figure.Image 
                    src="/services/gw.jpg"
                    alt="Green and Garden Waste"
                  />
                </Figure>
              </Col>
              <Col md={8}>
                <h4>Green and Garden Waste</h4>
                <p>As part of our maintenance schedules, we will remove any waste created from site and this in turn is taken to a recycling site which is reused after a period of time.</p>
              </Col>
            </Row>
          </Col>

          <Col md={6} className="mb-4">
            <Figure>
              <Figure.Image 
                src="/services/ic.jpg"
                alt="Maintenance Cleaning Service"
              />
            </Figure>
            <h4>Maintenance Cleaning Service</h4>
            <p>Our complete maintenance cleaning service will see your grounds looking their best.</p>

            <p>Operating on regular contract, Amazon Garden Services will visit your premises and perform comprehensive cleaning on your grounds at a very competitive price. With a full range of cleaning for paths, patios and more, we'll ensure your business maintains a clean, professional image.</p>

            <p>Patio & pathway cleaning - Moss & algae removal - Car parks cleaned</p>

            <p>No matter what the size of the premises or work involved, we always complete our work to the highest standard using qualified staff and the best equipment. Take advantage of our complete maintenance cleaning service for your business. Call us on <a href="tel:07808 763230">07808 763230</a>.</p>
          </Col>

          <Col md={6} className="mb-4">
            <Figure>
              <Figure.Image 
                src="/services/we2.jpg"
                alt="Weed Spraying"
              />
            </Figure>
            <h4>Weed Spraying</h4>
            <p>We are NPTC qualified and fully insured for weed control via knapsack spraying this means that you can be sure the correct product, dose rate and all environmental assessments have been complied with on site.</p>
            <Figure>
              <Figure.Image 
                src="/services/ad.jpg"
                alt="Call us now"
              />
            </Figure>
          </Col>
        </Row>
      </Container>
    </div>
  );
}