import { useState } from "react";
import { Row, Col, Button, Form, Alert } from "react-bootstrap";

export default function ContactForm() {
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [subject, setSubject] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [sending, setSending] = useState<boolean>(false);
  const [sent, setSent] = useState<boolean>(false);
  const [errored, setErrored] = useState<boolean>(false);

  const send = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setSending(true);
    setErrored(false);

    const result = await fetch("/api/sendEmail", {
      method: "POST",
      body: JSON.stringify({name, email, phone, subject, message})
    });

    setSending(false);
    
    if (result.ok) {
      setSent(true);
    } else {
      setErrored(true);
    }
  }

  if (sent) {
    return <Alert variant="success"><Alert.Heading>Message sent!</Alert.Heading>Thanks for your message. One of our team will be in touch soon!</Alert>
  }

  return (
    <Form.Group as="form" onSubmit={send}>
      <Row>
        <Col md={6} className="mb-4">
          <Form.Control placeholder="Your name *" name="name" id="name" onChange={(e) => setName(e.currentTarget.value)} required />
        </Col>
        <Col md={6} className="mb-4">
          <Form.Control placeholder="Your e-mail *" name="email" id="email" onChange={(e) => setEmail(e.currentTarget.value)} required type="email" />
        </Col>
        <Col md={6} className="mb-4">
          <Form.Control placeholder="Phone" name="phone" id="phone" onChange={(e) => setPhone(e.currentTarget.value)} type="tel" />
        </Col>
        <Col md={6} className="mb-4">
          <Form.Control placeholder="Subject" name="subject" id="subject" onChange={(e) => setSubject(e.currentTarget.value)} />
        </Col>
        <Col md={12} className="mb-4">
          <Form.Control type="textarea" placeholder="Type your message here..." name="message" aria-multiline height={200} as="textarea" rows={7} id="message" onChange={(e) => setMessage(e.currentTarget.value)} required />
        </Col>
        <Col md={12} className="mb-4">
          <Button variant="success" disabled={sending} type="submit">SUBMIT</Button>
        </Col>
          <Col md={12}>
            <Alert variant="danger" show={errored} dismissible onClose={() => setErrored(false)}><Alert.Heading>Something went wrong!</Alert.Heading>Try again or get in touch by email or phone using the details above.</Alert>
          </Col>
      </Row>
    </Form.Group>
  )
}