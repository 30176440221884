import { Button, Carousel } from "react-bootstrap";

const items = [
  { src: "slides/01.jpg" },
  { src: "slides/02.jpg" },
  { src: "slides/03.jpg" },
  { src: "slides/04.jpg" },
  { src: "slides/05.jpg" },
  { src: "slides/06.jpg" },
  { src: "slides/07.jpg" },
  { src: "slides/08.jpg" },
  { src: "slides/09.jpg" },
  { src: "slides/10.jpg" },
  { src: "slides/11.jpg" },
  { src: "slides/12.jpg" },
  { src: "slides/13.jpg" },
  { src: "slides/14.jpg" },
  { src: "slides/15.jpg" },
  { src: "slides/16.jpg" },
  { src: "slides/17.jpg" },
  { src: "slides/18.jpg" },
  { src: "slides/19.jpg" }
]

export default function Slideshow() {
  return (
    <div style={{height: "80vh", minHeight: "800px"}}>
      <Carousel fade pause={false} interval={5000}>
          {items.map((img, index) => {
            return (
              <Carousel.Item key={index} className="object-fit-contain w-100" style={{height: "80vh", minHeight: "800px"}}>
                <img src={img.src} alt="Amazon Garden Services" className="min-vw-100 min-h-100" />
                <Carousel.Caption>
                  <hr style={{boxShadow: "2px 2px #404040"}} />
                  <p style={{fontSize: "24px", textShadow: "2px 2px #404040"}}>Estate and Grounds Maintenance</p>
                  <p style={{fontSize: "28px", textShadow: "2px 2px #404040"}}>Amazon Garden Services</p>
                  <Button href="/contact" variant="dark" style={{paddingLeft: "16px", paddingRight: "16px"}}>Contact Us</Button>
                  <hr style={{boxShadow: "2px 2px #404040"}} />
                </Carousel.Caption>
              </Carousel.Item>
            )
          })}
      </Carousel>
    </div>
  )
}