import { Routes, Route, Outlet } from "react-router-dom";
import NavBar from "./components/NavBar";
import * as pages from "./pages";

import { Helmet } from 'react-helmet';

import './App.css'
import Footer from "./components/Footer";

export default function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<pages.Home />} />
        <Route path="home" element={<pages.Home />} />
        <Route path="about" element={<pages.About />} />
        <Route path="services" element={<pages.Services />} />
        <Route path="contact" element={<pages.Contact />} />

        <Route path="*" element={<pages.NotFound />} />
      </Route>
    </Routes>
  );
}

function Layout() {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Amazon Garden Services</title>
      </Helmet>
      <NavBar />
      <Outlet />
      <Footer />
    </div>
  );
}