import { Navbar, Container, Nav } from "react-bootstrap";

export default function NavBar() {
  return (
    <Navbar expand="md" className="bg-body-tertiary" variant="dark" fixed="top">
      <Container>
        <Navbar.Brand href="/" className="py-0">
          <img src="/logo-sm.png" alt="Amazon Garden Services" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
          <Nav>
            <Nav.Link style={{marginLeft: "20px"}} href="/">Home</Nav.Link>
            <Nav.Link style={{marginLeft: "20px"}} href="/about">About Us</Nav.Link>
            <Nav.Link style={{marginLeft: "20px"}} href="/services">Services</Nav.Link>
            <Nav.Link style={{marginLeft: "20px"}} href="/contact">Contact</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}