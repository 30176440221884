import { Button, Col, Container, Figure, Row } from "react-bootstrap";
import Slideshow from "../components/Slideshow";
import { Helmet } from 'react-helmet';

export default function Home() {
  return (
    <>
      <Helmet>
        <title>Amazon Garden Services - Home</title>
      </Helmet>
      <Slideshow />

      <Container style={{paddingTop: "100px"}}>
        <h2 className="section-title text-center" style={{marginBottom: "40px"}}>Welcome!</h2>
        <Row>
          <Col xs={12} md={6} lg={4}>
            <Figure>
              <Figure.Image
                alt="Who are we?"
                src="home/c1.jpg" 
                className="rounded-circle"
              />
              <Figure.Caption>
                <br />
                <h4 className="text-center text-md-start">Who are we?</h4>
                <p className="text-center text-md-start">Amazon Garden Services Ltd specialise in the provision of Grounds Maintenance and garden services to the commercial and domestic sectors. Our success has been due to our commitment to provide a high quality regular reliable service to our clients.</p>
              </Figure.Caption>
            </Figure>
          </Col>
          <Col xs={12} md={6} lg={4}>
            <Figure>
              <Figure.Image
                alt="Who are we?"
                src="home/c2.jpg" 
                className="rounded-circle"
              />
              <Figure.Caption>
                <br />
                <h4 className="text-center text-md-start">What do we do?</h4>
                <p className="text-center text-md-start">Amazon Garden Services provides a full range of grounds maintenance and management services to domestic &amp; commercial clients alike. No matter the job, we are committed to providing the highest levels of care and quality in all that we do.</p>
              </Figure.Caption>
            </Figure>
          </Col>
          <Col xs={12} md={{span: 6, offset: 3}} lg={{span: 4, offset: 0}}>
            <Figure>
              <Figure.Image
                alt="Who are we?"
                src="home/c3.jpg" 
                className="rounded-circle"
              />
              <Figure.Caption>
                <br />
                <h4 className="text-center text-md-start">How can we help?</h4>
                <p className="text-center text-md-start">Amazon Garden Services has worked closely with clients across a broad range of sectors. We provide a professional service that is tailored to clients unique requirements whether it&apos;s a housing estate or a housing association looking to provide an attractive environment for the residents.</p>
              </Figure.Caption>
            </Figure>
          </Col>
        </Row>
        <Row>
          <p className="text-center fs-5">Areas Served: Portsmouth, Southampton, Fareham, Chichester and the surrounding areas.</p>
        </Row>
        <Row style={{marginBottom: "100px", marginTop: "24px"}}>
          <Button style={{width: "240px"}} className="mx-auto" variant="success" href="/contact">Contact Us</Button>
        </Row>
      </Container>
    </>
  );
}