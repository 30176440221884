import { Col, Container, Figure, Row } from "react-bootstrap"
import { Helmet } from "react-helmet";

export default function About() {
  return (
    <div style={{marginTop: "72px"}}>
      <Helmet>
        <title>Amazon Garden Services - About Us</title>
      </Helmet>
        <div style={{background: "no-repeat center url(/about/bg.jpg)", backgroundSize: "cover", position: "relative", paddingTop: "120px", paddingBottom: "120px"}}>
          <h1 className="text-center" style={{color: "#FFF"}}>About Us</h1>
        </div>
        <Container style={{paddingTop: "100px", paddingBottom: "100px"}}>
          <h2 className="section-title text-center" style={{marginBottom: "40px"}}>Why choose us?</h2>

          <Row>
            <Col lg={6} className="text-md-center">
            <Figure>
              <Figure.Image 
                src="/about/about_01.jpg"
                alt="Why choose us?"
              />
            </Figure>
            </Col>
            <Col>
              <p>With over twenty years of experience, you can be assured of a job well done. We pride ourselves on our attention to detail. We are happy to cater to the needs of both commercial & domestic clients. With a wide range of garden services, there's not much we can't do.</p>
              <h4>Work Overview</h4>
              <p>We Cover:</p>
              <ul>
                <li>Commercial grounds maintenance</li>
                <li>Working closely with management companies</li>
                <li>Residential Homes</li>
                <li>Private Developments</li>
                <li>Industrial sites</li>
              </ul>
            </Col>
          </Row>
        </Container>
    </div>
  );
}